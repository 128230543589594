<template>
  <div id="app">
    <div class="info">
      <div class="single">
        <div class="single_left">App Name</div>
        <div class="single_right">Download</div>
      </div>
      <div class="single">
        <div class="single_left">Mac Mini Laser Engraver</div>
        <div class="single_right2">
          <div class="upload" @click="dowload(1)">.zip</div>
          <div class="upload" @click="dowload(3)">.rar</div>
          <div class="upload" @click="dowload(5)">.dmg</div>
        </div>
      </div>
      <div class="single">
        <div class="single_left">Windows Mini Laser Engraver</div>
        <div class="single_right2">
          <div class="upload" @click="dowload(2)">.zip</div>
          <div class="upload" @click="dowload(4)">.rar</div>
          <div class="upload" @click="dowload(6)">.exe</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},

  methods: {
    // 下载
    dowload(num) {
      switch (num) {
        case 1:
          window.open(
            "https://acc-huahui.oss-cn-shenzhen.aliyuncs.com/grave/Mini%20Laser%20Engraver.zip"
          );
          break;
        case 2:
          window.open(
            "https://acc-huahui.oss-cn-shenzhen.aliyuncs.com/grave/Mini%20Laser%20Engraver%20for%20windows.zip"
          );
          break;
        case 3:
          window.open(
            "https://acc-huahui.oss-cn-shenzhen.aliyuncs.com/grave/Mini Laser Engraver.rar"
          );
          break;
        case 4:
          window.open(
            "https://acc-huahui.oss-cn-shenzhen.aliyuncs.com/grave/Mini Laser Engraver for windows.rar"
          );
          break;
        case 5:
          window.open(
            "https://acc-huahui.oss-cn-shenzhen.aliyuncs.com/grave/Mini Laser Engraver.dmg"
          );
          break;
        case 6:
          window.open(
            "https://acc-huahui.oss-cn-shenzhen.aliyuncs.com/grave/Mini Laser Engraver.exe"
          );
          break;
      }
    },
  },
};
</script>

<style  scoped>
#app {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("https://boiler.srthinker.com/img/download.f96137f4.png")
    no-repeat top;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}
.info {
  background: white;
  width: 1080px;
}
.single {
  display: flex;
  height: 70px;
  width: 100%;
  align-items: center;
}
.single_left {
  width: 62.5%;
  height: 100%;
  line-height: 70px;
  padding-left: 10px;
  box-sizing: border-box;
  font-size: 12px;
  font-weight: 600;
  border: 1px solid #ccc;
}
.single_right {
  flex: 1;
  height: 100%;
  padding-left: 10px;
  box-sizing: border-box;
  font-size: 12px;
  font-weight: 600;
  line-height: 70px;
  border: 1px solid #ccc;
}
.single_right2 {
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 10px;
  box-sizing: border-box;
  border: 1px solid #ccc;
}
.upload {
  width: 71px;
  height: 32px;
  background: #1989fa;
  border: 1px solid #1989fa;
  line-height: 32px;
  font-size: 12px;
  text-align: center;
  color: white;
  font-weight: 400;
  cursor: pointer;
  border-radius: 2px;
  margin-right: 10px;
}
</style>
